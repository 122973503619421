import React, { useEffect } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Footer } from "../../../components/footer";
import DashboardNav from "../../../components/navbar";
import {
  RealmAppProvider,
  OnboardingRedirector,
  APP_ID,
  useRealmApp,
} from "../../../components/realm_app";
import { EditIcon } from "../../../components/icons";
import {
  DAYS_IN_WEEK,
  getNextMeetingTime,
  getRelationTitle,
} from "../../../utils";
import "./team.css";

const WaitOnMentorDiv = (mentorName) => {
  return (
    <>
      <h5>
        <i>{mentorName}</i> has not set a meeting time yet.
      </h5>
      <p>
        <i>{mentorName}</i> should be reaching out to you shortly to set up a
        time. If you don't hear back from them soon, feel free to proactively
        reach out to them.
      </p>
    </>
  );
};

const getBrowserTimeZone = () => {
  const d = new Date();
  try {
    const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "short" });
    // Format the date to parts, and pull out the value of the time zone name
    const result = dtf
      .formatToParts(d)
      .find((part) => part.type == "timeZoneName").value;
    return result;
  } catch (e) {
    console.log("Intl timezone fetch failed");
  }

  try {
    return d
      .toString()
      .match(/\((.*)\)/)
      .pop();
  } catch (e) {
    console.log("toString timezone fetch failed");
  }

  return "your local time";
};

const Scheduler = ({
  toggleEditMode,
  startDate,
  timestamp,
  editModeDefault = false,
}) => {
  const realmApp = useRealmApp();

  const [dateString, setDateString] = React.useState("");
  const [time, setTime] = React.useState("");
  const [loading, setLoading] = React.useState("Save");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!dateString || !time) {
      alert("Please pick a date and time.");
      return;
    }

    const date = new Date(dateString.replace(/-/g, "/"));
    const [hours, minutes] = time.split(":");
    date.setHours(parseInt(hours), parseInt(minutes), 0, 0);

    if (isNaN(date.getTime())) {
      alert(
        "Something went wrong. Please try again, or contact MentorAmp support."
      );
      return;
    }

    const now_ts = new Date().getTime();
    const new_ts = date.getTime();

    if (now_ts >= new_ts) {
      alert("Error: Proposed meeting time is in the past.");
      return;
    }
    if (new_ts - now_ts > 1000 * 60 * 60 * 24 * 30) {
      alert("Please set your meeting time to within the next month.");
      return;
    }

    let originalLastMtgTime = await realmApp.appCurrentUser?.functions.getLastMeetingTime(
      realmApp.app.currentUser?.customData.menteeMeetingTime
    );
    if (!originalLastMtgTime) {
      originalLastMtgTime =
        realmApp.app.currentUser?.customData.menteeMeetingTime
          ?.previousScheduleLastMtg;
    }

    setLoading("Saving new time...");

    if (originalLastMtgTime) {
      await realmApp.setMongoUserAttribute({
        menteeMeetingTime: {
          frequency: "Weekly",
          first_timestamp: Number(new_ts),
          previousScheduleLastMtg: originalLastMtgTime,
        },
      });
    } else {
      await realmApp.setMongoUserAttribute({
        menteeMeetingTime: {
          frequency: "Weekly",
          first_timestamp: Number(new_ts),
        },
      });
    }

    let originalNextMtgTime = getNextMeetingTime(DAYS_IN_WEEK, timestamp);
    if (!originalNextMtgTime) {
      console.log(
        "Did not calculate original next meeting from current user's data. Looking at mentee records..."
      );
      // Look at mentee's goals record to see if they had an originalLastMtgTime
      // that never happened with previous mentor.
      const menteeLastRecord = await realmApp.appCurrentUser?.functions.getLastGoals2RecordDate(
        realmApp.appCustomData?.mentee
      );
      console.log("Mentee's last record: menteeLastRecord");
      if (menteeLastRecord > now_ts) {
        originalNextMtgTime = menteeLastRecord;
      }
    }
    try {
      await realmApp.appCurrentUser?.functions.editRecordsDateForUpcomingMeeting(
        realmApp.appCustomData?.mentee,
        originalNextMtgTime,
        new_ts
      );
    } catch (e) {
      console.log("Did not update mentee's latest submission: ", e);
    }

    window.location.reload();
    setTimeout(() => setLoading("Save"), 300);
  };

  const checkAndSetTime = (e) => {
    e.target.type !== "time"
      ? alert(
          "Internet explorer not supported. Please use a different browser."
        )
      : setTime(e.target.value);
  };

  const checkAndSetDate = (e) => {
    e.target.type !== "date"
      ? alert(
          "Internet explorer not supported. Please use a different browser."
        )
      : setDateString(e.target.value);
  };

  return (
    <>
      <h5 className="pb-2 d-inline-block">Weekly Meeting Time</h5>
      {!editModeDefault && (
        <span className="edit-button" onClick={() => toggleEditMode()}>
          X
        </span>
      )}
      <form className="scheduling-line" onSubmit={handleSubmit}>
        <input
          type="time"
          step="900"
          name="meeting-time"
          defaultValue={time}
          onChange={(e) => checkAndSetTime(e)}
          onInput={(e) => checkAndSetTime(e)}
        />
        <h6 className="ml-2 mr-2"> {getBrowserTimeZone()} starting on </h6>
        <input
          type="date"
          name="meeting-date"
          defaultValue={startDate}
          onChange={(e) => checkAndSetDate(e)}
          onInput={(e) => checkAndSetDate(e)}
        />
        <div className="d-block">
          <Button className="deepen-blue-button" type="submit">
            {loading}
          </Button>
        </div>
      </form>
    </>
  );
};

const parseMenteeMeetingTime = (meetingTimeDict) => {
  var timestamp = null;
  if (meetingTimeDict && "first_timestamp" in meetingTimeDict) {
    let maybe_timestamp = meetingTimeDict["first_timestamp"];
    if (!isNaN(maybe_timestamp)) {
      timestamp = maybe_timestamp;
    } else if (
      maybe_timestamp &&
      isNaN(maybe_timestamp) &&
      "$numberLong" in maybe_timestamp
    ) {
      timestamp = Number(maybe_timestamp["$numberLong"]);
    }
  }
  return timestamp;
};

const MeetingTimeDiv = ({
  meetingTimestamp,
  canReschedule,
  partnerName,
  editModeDefault = false,
}) => {
  const realmApp = useRealmApp();

  const [editMode, setEditMode] = React.useState(editModeDefault);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  let meetingDay = "--";
  let meetingTime = "--";
  let startDate = "--";
  let currentCalLink = "";

  if (meetingTimestamp) {
    meetingDay = moment(meetingTimestamp).format("dddd");
    meetingTime = moment(meetingTimestamp).format("LT");
    startDate = moment(meetingTimestamp).format("MM/DD/YYYY");

    const MS_IN_HOUR = 60 /*minutes*/ * 60 /*seconds*/ * 1000; /*ms*/
    const nextMtgTime = getNextMeetingTime(DAYS_IN_WEEK, meetingTimestamp);
    const startTimeStr =
      new Date(nextMtgTime)
        .toISOString()
        .replace(/-/g, "")
        .replace(/:/g, "")
        .split(".")[0] + "Z";
    const endTimeStr =
      new Date(nextMtgTime + MS_IN_HOUR)
        .toISOString()
        .replace(/-/g, "")
        .replace(/:/g, "")
        .split(".")[0] + "Z";
    currentCalLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${partnerName}/${realmApp.app.currentUser?.customData.username}&dates=${startTimeStr}/${endTimeStr}&recur=RRULE:FREQ=WEEKLY`;
  }

  return editMode ? (
    <Scheduler
      toggleEditMode={toggleEditMode}
      startDate={startDate}
      timestamp={meetingTimestamp}
      editModeDefault={editModeDefault}
    />
  ) : (
    <>
      <h5 className="pb-2 d-inline">Weekly Meeting Time</h5>
      {canReschedule && (
        <div className="edit-button" onClick={() => toggleEditMode()}>
          <EditIcon /> Edit
        </div>
      )}
      <h6 className="pt-2">
        {meetingTime} {getBrowserTimeZone()} every {meetingDay} starting on{" "}
        {startDate}
        <br />
        <br />
        {currentCalLink && (
          <a href={currentCalLink} target="_blank">
            <Button className="deepen-blue-button">Add to calendar</Button>
          </a>
        )}
      </h6>
    </>
  );
};

const ContactCard = (
  title,
  name,
  phone,
  email,
  pictureSrc,
  menteeMeetingDiv,
  enableSubHeading
) => {
  const phoneDialStr = `tel:+${phone}`;
  const mailToStr = `mailto:${email}`;
  const [firstName] = name ? name.split(" ") : "";
  const titleMargin = enableSubHeading ? "mb-1" : "mb-3";

  return (
    <>
      <h4 className={titleMargin}>{title.toUpperCase()}</h4>
      {enableSubHeading && (
        <p className="team-type-subheading">
          Please contact {firstName} to schedule a time to meet every week.
        </p>
      )}
      {email && (
        <div className="light-card contact-card">
          {pictureSrc && <img src={pictureSrc} alt="" />}
          <div className="contact-info">
            <h5>{name}</h5>
            <p>
              <a href={phoneDialStr}>{phone}</a>
            </p>
            <p>
              <a href={mailToStr}>{email}</a>
            </p>
          </div>
          <div className="schedule-info">{menteeMeetingDiv}</div>
        </div>
      )}
      {!email && (
        <div className="light-card contact-card-empty">
          <h3>We're not quite ready!</h3>
          <h6>
            You haven't been matched with a {title} yet. <br />
            Come back here after you've been matched.
          </h6>
        </div>
      )}
    </>
  );
};

const TeamPage = () => {
  const realmApp = useRealmApp();

  const [mentorInfo, setMentorInfo] = React.useState(<>loading...</>);
  const [menteeInfo, setMenteeInfo] = React.useState(<>loading...</>);

  async function getMentorMenteeAndSetMeetingTimeDivs() {
    const mentorMenteeMap = await realmApp.fetchMentorMentee({
      name: 1,
      phone: 1,
      username: 1,
      menteeMeetingTime: 1,
    });

    const mentor = mentorMenteeMap.get("mentor");
    const mentee = mentorMenteeMap.get("mentee");

    if (mentee) {
      console.log(`fetching profile picture for ${mentee["name"]}`);
      const pictureSrc = await fetchProfilePictureSrc(mentee["_id"]);
      const menteeMeetingTimestamp = parseMenteeMeetingTime(
        realmApp.app.currentUser?.customData.menteeMeetingTime
      );

      console.log(menteeMeetingTimestamp);
      const isFirstTimeScheduling = menteeMeetingTimestamp ? false : true;
      setMenteeInfo(
        ContactCard(
          getRelationTitle("mentee"),
          mentee["username"],
          mentee["phone"],
          mentee["name"],
          pictureSrc,
          <MeetingTimeDiv
            meetingTimestamp={menteeMeetingTimestamp}
            canReschedule={true}
            partnerName={mentee["username"]}
            editModeDefault={isFirstTimeScheduling}
          />,
          isFirstTimeScheduling
        )
      );
    } else {
      setMenteeInfo(
        ContactCard(
          getRelationTitle("mentee"),
          null,
          null,
          null,
          null,
          null,
          false
        )
      );
    }

    if (mentor) {
      let menteeMeetingDiv = <>loading...</>;
      const pictureSrc = await fetchProfilePictureSrc(mentor["_id"]);
      const meetTimestamp = parseMenteeMeetingTime(mentor["menteeMeetingTime"]);
      const isFirstTimeScheduling = meetTimestamp ? false : true;
      if (meetTimestamp) {
        menteeMeetingDiv = (
          <MeetingTimeDiv
            meetingTimestamp={meetTimestamp}
            partnerName={mentor["username"]}
            canReschedule={false}
          />
        );
      } else {
        menteeMeetingDiv = WaitOnMentorDiv(mentor["username"]);
      }
      setMentorInfo(
        ContactCard(
          getRelationTitle("mentor"),
          mentor["username"],
          mentor["phone"],
          mentor["name"],
          pictureSrc,
          menteeMeetingDiv,
          isFirstTimeScheduling
        )
      );
    } else {
      setMentorInfo(
        ContactCard(
          getRelationTitle("mentor"),
          null,
          null,
          null,
          null,
          null,
          false
        )
      );
    }
  }

  // TODO: UNCOMMENT TO ADD BACK FACILITATOR
  // async function fetchProgramManager() {
  //   const circle = await realmApp.fetchCircle();
  //   if (circle) {
  //     setPMInfo(
  //       ContactDiv(
  //         "facilitator",
  //         circle["program_manager"]["name"],
  //         circle["program_manager"]["phone"],
  //         circle["program_manager"]["email"],
  //         null,
  //         null
  //       )
  //     );
  //   }
  // }
  async function fetchProfilePictureSrc(userId: string) {
    const picture = await realmApp.db
      .collection("ProfilePicture")
      .findOne({ user_id: userId });

    return picture ? picture.picture : null;
  }

  async function maybeSetNotificationBooleans() {
    let user_data = realmApp.app.currentUser?.customData;
    if (!Boolean(user_data?.mentor)) {
      return;
    }
    realmApp.maybeSetMongoUserBoolean("hasViewedMentorInfo");
    realmApp.maybeSetMongoUserBoolean("hasViewedNewSchedule");
  }

  useEffect(() => {
    getMentorMenteeAndSetMeetingTimeDivs();
    // fetchProgramManager();
    maybeSetNotificationBooleans();
  }, []);

  return (
    <>
      <div className="page">
        <DashboardNav />
        <br />
        <h2>My Team</h2>
        <h6 className="mb-5">
          It’s important to honor your time commitment with your accountability
          team. If you need to reschedule, please contact your team member at
          least one day in advance.
        </h6>
        <div className="page-content">
          {menteeInfo}
          {mentorInfo}
          {/* {PMInfo} */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <TeamPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
